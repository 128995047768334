<template lang="pug">
  .w-telegram.widget.widget--white(id="telegram")
    .w-telegram__decor-top
      illustration-decor
    .w-telegram__decor-bottom
      illustration-decor
    .w-telegram__content
      img.w-telegram__profile(src="@/assets/img/profile-03.png")
      p.w-telegram__text(v-html="$t('telegram.text_1')")
      a.btn.btn--flat(
        target="_blank"
        href="https://t.me/joinchat/_PWIioSP9IIxNDFk")
        icon-telegram
        span {{ $t('base.join') }}
    made-by-invito
</template>

<script>
import IconTelegram from '@/components/svg/IconTelegram'
import IllustrationDecor from '@/components/svg/IllustrationDecor'
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'TelegramWidget',

  components: {
    IconTelegram,
    IllustrationDecor,
    MadeByInvito
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-telegram {
    position: relative;
    overflow: hidden;
    color: $color-white;
    background-color: $color-primary;

    &__decor-top {
      color: $color-secondary;
      position: absolute;
      top: -.2rem;
      left: -3.5rem;
      transform: rotate(180deg);
    }

    &__decor-bottom {
      color: $color-secondary;
      position: absolute;
      bottom: -.2rem;
      right: -3.5rem;
    }

    &__content {
      position: relative;
      padding: 16rem 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &__profile {
      width: 15rem;
      margin-bottom: 5rem;
    }

    &__text {
      margin-bottom: 5rem;
    }
  }
</style>
