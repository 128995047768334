<template functional>
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.45545 10.5451L7.14115 15.0122C7.59082 15.0122 7.78558 14.817 8.01912 14.5826L10.1274 12.5467L14.4959 15.7794C15.2971 16.2306 15.8616 15.993 16.0777 15.0346L18.9452 1.45712L18.946 1.45632C19.2001 0.259523 18.5177 -0.208475 17.7371 0.085124L0.882079 6.60589C-0.268241 7.05709 -0.250824 7.70508 0.686532 7.99868L4.99568 9.35308L15.005 3.02431C15.476 2.70911 15.9043 2.88351 15.552 3.19871L7.45545 10.5451Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTelegram'
}
</script>

<style>
</style>
