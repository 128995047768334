<template lang="pug">
  .w-address.widget
    .w-address__content
      .w-address__decor
        illustration-flower
      .title {{ $t('base.ceremony') }}
      p.w-address__text(v-html="$t('info.address_1')")
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/cgdYqS6qdXjPTQD38")
        | {{ $t('base.look_at_map') }}
      .w-address__decor
        illustration-flower
      .title {{ $t('base.banquet') }}
      p.w-address__text(v-html="$t('info.address_2')")
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/aGoWiJoTwxS2tAvn7")
        | {{ $t('base.look_at_map') }}
</template>

<script>
import IllustrationFlower from '@/components/svg/IllustrationFlower'

export default {
  name: 'AddressWidget',

  components: {
    IllustrationFlower
  }
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-address {
    position: relative;

    &__decor {
      width: 4rem;
      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__text {
      margin-top: 1.6rem;
      margin-bottom: 2rem;
      line-height: 2.4rem;
    }

    &__link {
      position: relative;
      display: inline-block;
      color: $color-primary;
      font-size: 1.2rem;
      letter-spacing: 5%;
      padding: 1.2rem 2.4rem;
      background-color: rgba($color-secondary, .4);
      text-decoration: none;
      text-transform: none;
      transition: .1s;

      &:active {
        transform: scale(.97);
        background-color: rgba($color-secondary, 1);
      }

      &:visited {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-bottom: 9rem;
      }
    }

    &__content {
      padding: 16rem 0rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
</style>
