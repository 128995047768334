<template lang="pug">
  .w-info.widget
    .w-info__decor-top
      illustration-decor
    .w-info__decor-bottom
      illustration-decor
    .w-info__content
      template(v-if="hasGuests")
        .small-title {{ title }}
        .w-info__subtitle {{ guests }}
        p.w-info__subsubtitle(v-html="$t('base.caption', { you })")
      .w-info__date
        .w-info__date-el {{ $t('base.december') }}
        .w-info__date-center 12
        .w-info__date-el {{ $t('base.sunday') }}
      template(v-if="hasGuests")
        p(v-html="$t('base.caption_2')")
</template>

<script>
import IllustrationDecor from '@/components/svg/IllustrationDecor'

export default {
  name: 'InfoWidget',

  components: {
    IllustrationDecor
  },

  props: {
    invitation: Object
  },

  computed: {
    title () {
      if (this.invitation && this.invitation.texts.caption) {
        return this.$t(this.invitation.texts.caption)
      }
      return this.$t('base.dear_our')
    },

    hasGuests () {
      return this.invitation &&
        this.invitation.guests.length
    },

    guests () {
      if (this.invitation) {
        return this.invitation.guests
          .map(item => {
            const index = item.name.indexOf('(')
            if (index !== -1) {
              return item.name.substring(0, index - 1)
            }
            return item.name
          })
          .join(', ')
          .replace(/,(?=[^,]*$)/, ` ${this.$t('base.and')}`)
      }
      return '-'
    },

    you () {
      if (this.invitation && this.invitation.texts.you) {
        return this.$t(this.invitation.texts.you)
      }
      return this.$t('base.you')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-info {
    position: relative;
    padding-top: 18rem;
    padding-bottom: 18rem;
    color: $color-white;
    background-color: $color-primary;

    &__decor-top {
      color: $color-white;
      position: absolute;
      top: -.5rem;
      left: -3.5rem;
      transform: rotate(180deg);
    }

    &__decor-bottom {
      color: $color-white;
      position: absolute;
      bottom: -.5rem;
      right: -3.5rem;
    }

    &__date {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      margin-bottom: 3rem;

      &-el {
        font-size: 1.4rem;
        padding: 1rem 1.4rem;
        border-top: 1px solid $color-white;
        border-bottom: 1px solid $color-white;
      }

      &-center {
        font-size: 6.4rem;
        font-family: "FoglihtenNo06";
        margin-right: 1.4rem;
        margin-left: 1.4rem;
      }
    }

    &__content {
      position: relative;
      padding: 0 2rem;
      text-align: center;
      z-index: 1;

      p {
        font-size: 1.4rem;
      }
    }

    &__subtitle {
      font-size: 3rem;
      font-family: "FoglihtenNo06";
      display: inline-block;
      text-align: center;
      min-width: 27rem;
      margin-top: 2rem;
      line-height: 4.5rem;
      margin-bottom: 2rem;
    }
  }
</style>
