<template lang="pug">
  .w-present.widget
    img.w-present__icon(src="@/assets/svg/present.svg")
    p.w-present__paragrapth(v-html="$t('presents.text_1')")
    p.w-present__paragrapth(v-html="$t('presents.text_2')")
    v-dropdown
      button.btn.w-present__btn {{ $t('presents.make_present') }}
      template(slot="content")
        .w-present__info
          p.w-present__info-label {{ $t('presents.bank') }}
          p.w-present__info-label {{ $t('presents.number') }}
</template>

<script>
export default {
  name: 'InfoWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-present {
    padding: 12rem 2.4rem;
    text-align: center;

    &__icon {
      display: inline-block;
      margin-bottom: 4.5rem;
      width: 4rem;
    }

    &__btn {
      background-color: rgba($color-secondary, .4);
    }

    &__paragrapth {
      margin-bottom: 5.4rem;
    }

    &__info {
      padding: 1.5rem;
      font-size: 1.6rem;
      border-radius: .8rem;
      background-color: $color-white;
      box-shadow: 0 0 18px rgba(0, 0, 0, .05);

      &-title {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }

      &-label {
        user-select: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }

    .btn {
      margin: 0 auto;
    }
  }
</style>
