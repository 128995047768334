<template lang="pug">
  .w-not-invitation-id.widget.widget--screen-size
    img.w-not-invitation-id__logo(src="@/assets/svg/invito.svg")
    .w-not-invitation-id__content
      img.w-not-invitation-id__heart(src="@/assets/svg/link.svg")
      p(v-html="$t('texts.no_invitation_id')")
    made-by-invito
</template>

<script>
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'NoInvitationId',

  components: {
    MadeByInvito
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .w-not-invitation-id {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    background-color: $color-primary;

    &__logo {
      position: absolute;
      top: 3rem;
      width: 7rem;
      margin: 0 auto;
    }

    &__heart {
      width: 5rem;
      margin-bottom: 3rem;
    }

    &__content {
      color: $color-white;
      font-size: 2.2rem;
      font-weight: 300;
      text-align: center;
      line-height: 2.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
