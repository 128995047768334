<template lang="pug">
  .widget.timing-widget
    .timing-widget__decor-top
      illustration-decor
    .timing-widget__decor-bottom
      illustration-decor
    .timing-widget__content
      .title(v-html="$t('timing.title')")
      .timing-widget__item(
        v-for="(item, index) in items"
        :key="index")
        .timing-widget__item-time {{ item.time }}
        .timing-widget__item-label {{ $t(item.label) }}
      p.timing-widget__ps(v-html="$t('timing.text_1')")
</template>

<script>
import IllustrationDecor from '@/components/svg/IllustrationDecor'

export default {
  name: 'TimingWidget',

  components: {
    IllustrationDecor
  },

  data: () => ({
    items: [
      { time: '12:00', label: 'timing.item_1' },
      { time: '13:00', label: 'timing.item_2' },
      { time: '14:00', label: 'timing.item_3' },
      { time: '14:30', label: 'timing.item_4' },
      { time: '17:00', label: 'timing.item_5' },
      { time: '18:00', label: 'timing.item_6' },
      { time: '19:00', label: 'timing.item_7' },
      { time: '20:00', label: 'timing.item_8' }
    ]
  })
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .timing-widget {
    padding: 12rem 0rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    .title {
      text-align: center;
      margin-bottom: 5rem;
    }

    &__decor-top {
      color: $color-secondary;
      position: absolute;
      top: -.2rem;
      left: -3.5rem;
      transform: rotate(180deg);
    }

    &__decor-bottom {
      color: $color-secondary;
      position: absolute;
      bottom: -.2rem;
      right: -3.5rem;
    }

    &__content {
      z-index: 1;
    }

    &__ps {
      margin-top: 5rem;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-left: 3rem;
      margin-right: 3rem;

      &:not(:last-child) {
        margin-bottom: 3.5rem;
      }

      &-time {
        font-size: 2.2rem;
        font-family: "FoglihtenNo06";
        margin-right: 2rem;
      }

      &-label {
        font-size: 1.6rem;
        line-height: 2rem;
        display: inline-flex;
      }
    }
  }
</style>
