<template lang="pug">
  .w-dress-widgets(id="dress")
    illustration-flower
    p(v-html="$t('dress.text_1')")
    .w-dress-widgets__colors
      .w-dress-widgets__colors-item(
        v-for="(item, index) in colors"
        :key="index"
        :style="{ backgroundColor: item }")
      .w-dress-widgets__colors-item
        img(src="@/assets/img/color.png")
</template>

<script>
import IllustrationFlower from '@/components/svg/IllustrationFlower'

export default {
  name: 'DressWidgets',

  components: {
    IllustrationFlower
  },

  data: () => ({
    colors: [
      '#FFFFFF',
      '#BCC5C4',
      '#B9CFDC',
      '#85A0AB',
      '#8294A0',
      '#415463'
    ]
  })
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-dress-widgets {
    padding: 10rem 0;
    text-align: center;
    color: $color-white;
    background-color: $color-primary;

    &__colors {
      display: flex;
      justify-content: center;
      margin-top: 3.5rem;

      &-item {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    svg {
      margin-bottom: 3.5rem;
    }
  }
</style>
